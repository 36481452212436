<template>
    <div class="edit-profile-container">
        <h3>Edit Profile</h3>
        <profile-form ref="profile_form" @saved-user-profile="savedUserProfile" />
        <div class="submit-profile">
            <button class="btn btn-primary" @click="$refs.profile_form.submitProfile()">
                Save changes
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditProfileSetting",
    components: {
        ProfileForm: () => import(/* webpackChunkName: "profile-form" */ "@c/organisms/profile-form")
    },
    methods: {
        savedUserProfile() {
            this.$notify({
                group: "app-notifications",
                type: "success",
                text: "Your profile have been updated",
                duration: 3000
            });
        }
    }
}
</script>

<style lang="scss" scoped>
 .edit-profile-container {
    padding: 0 30px;

    h3 {
        margin-bottom: 40px;
    }

    .profile-form {
        /deep/ .profile-picture {
            margin-left: 25px;

            span {
                display: none;
            }
        }
    }

    .submit-profile {
        display: flex;
        justify-content: flex-end;
    }
 }
</style>